const initialState = {
    projects: '',
    status: [],
    priority: [],
    currentView: ''
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROJECTS': 
            return { ...state, projects: action.payload }
        case 'SET_STATUS': 
            return { ...state, status: action.payload }
        case 'SET_PRIORITY': 
            return { ...state, priority: action.payload }
        case 'CURRENT_VIEW': 
            return { ...state, currentView: action.payload }
        case 'LOGOUT':
                return { ...initialState }
        default: {
            return state;
        }
    }
}

export default projectReducer;